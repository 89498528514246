import { useGtm } from '@gtm-support/vue-gtm'

class DatepickerModule {
  sendDatepickerResetDateEvent (): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }

    const eventData = {
      event: 'datepicker',
      datepicker_interaction: 'reset_date'
    }

    gtm.trackEvent(eventData)
  }

  sendDatepickerCloseEvent (): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }

    const eventData = {
      event: 'datepicker',
      datepicker_interaction: 'close'
    }

    gtm.trackEvent(eventData)
  }

  sendDatepickerArrivalDateEvent (): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }

    const eventData = {
      event: 'datepicker',
      datepicker_interaction: 'arrival_date'
    }

    gtm.trackEvent(eventData)
  }

  sendDatepickerDepartureDateEvent (): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }

    const eventData = {
      event: 'datepicker',
      datepicker_interaction: 'departure_date'
    }

    gtm.trackEvent(eventData)
  }
}

export default DatepickerModule
