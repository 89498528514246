import HttpFactory from '../HttpFactory'
import IBasicArrayResponse from '../interfaces/IBasicArrayResponse'
// import IBasicObjectResponse from '../interfaces/IBasicObjectResponse'

class AmenitiesModule extends HttpFactory {
  private RESOURCE = 'api/amenities'

  async getAllAmenities () {
    return await this.callUseAsyncData<IBasicArrayResponse>(
      'GET',
      `${this.RESOURCE}`
    )
  }
}

export default AmenitiesModule
