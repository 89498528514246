import SearchParamsHelper from './SearchParamsHelper'
import type SearchParams from '~/types/search/SearchParams'

const ALLOWED_PATHS: string[] = ['/search/', '/lastminute/']

class UrlHashSearchParams {
  public get (): SearchParams|undefined {
    let str = window.location.hash
    str = (str.charAt(0) === '#') ? str.substring(1) : str

    const urlParams = new URLSearchParams(str)
    const entries = urlParams.entries()

    const data = {}

    for (const [key, val] of entries) {
      if (val === 'NaN') {
        continue
      }

      let isArray = false
      let newKey = key
      if (key.endsWith('[]')) {
        isArray = true
        newKey = key.slice(0, -2)
      }
      if (key.endsWith('%255B%255D')) {
        isArray = true
        newKey = key.slice(0, -10)
      }
      if (key.endsWith('%5B%5D')) {
        isArray = true
        newKey = key.slice(0, -6)
      }

      const isNumberValue = [
        'page',
        'rows',
        'kinderbetten',
        'einzelbetten',
        'doppelbetten',
        'einzelzimmer',
        'ausstattung',
        'preis',
        'preis_min',
        'variabilitaet',
        'personen',
        'onlinebuchung',
        'free_cancellation',
        'serp_highlight'
      ].includes(newKey)

      if (!isArray) {
        data[newKey] = isNumberValue ? Number(val) : val
      }

      if (!Object.prototype.hasOwnProperty.call(data, newKey)) {
        data[newKey] = []
      }

      if (Array.isArray(data[newKey]) && !data[newKey].includes(val)) {
        data[newKey].push(
          isNumberValue ? Number(val) : val
        )
      }
    }

    if (Object.keys(data).length === 0) {
      return undefined
    }

    return data
  }

  public set (newPageNumber: number = 0, newSearchParams: SearchParams): void {
    const pathPrefix: string|null = this.getPathPrefix()
    const oldPageNumber = this.getPageNumber()
    const oldSearchParams = this.get()

    if (pathPrefix === null) {
      return
    }

    if (
      oldSearchParams !== undefined &&
      SearchParamsHelper.areSearchParamsObjectsIdentical(oldSearchParams, newSearchParams) &&
      oldPageNumber === newPageNumber
    ) {
      return
    }

    if (
      oldSearchParams === undefined &&
      Object.keys(newSearchParams).length === 0 && newSearchParams.constructor === Object &&
      oldPageNumber === newPageNumber
    ) {
      return
    }

    // TODO: Remove commented out code
    // let newLocation = location.href.split('#')[0].split('?')[0]
    let newLocation = location.protocol + '//' + location.host + pathPrefix
    newLocation += this.convertPageNumberToPathParamString(pathPrefix, newPageNumber)
    newLocation += this.convertSearchParamsToHashParamsString(newSearchParams)

    // let newPath = pathPrefix
    // newPath += this.convertPageNumberToPathParamString(pathPrefix, newPageNumber)
    // const newHash = this.convertSearchParamsToHashParamsString(newSearchParams)

    window.setTimeout(function () {
      // TODO: Remove console.log() and commented out code
      // eslint-disable-next-line no-console
      console.log('Pushing state to history')

      history.pushState({ qry: newLocation }, '', newLocation)
      // const router = useRouter()
      // router.push({
      //   path: newPath,
      //   hash: newHash
      // })

      // TODO: Remove console.log()
      // eslint-disable-next-line no-console
      console.log('Calling GTM code')

      const { $gtm } = useNuxtApp()
      $gtm.content.contentView(window.location.pathname + window.location.search + window.location.hash)
    }, 300)
  }

  public getPathPrefix (): string|null {
    const str = window.location.pathname
    if (str.length < 2) {
      return null
    }
    for (let i = 0; i < ALLOWED_PATHS.length; i++) {
      if (str.startsWith(ALLOWED_PATHS[i])) {
        return ALLOWED_PATHS[i]
      }
    }
    return null
  }

  public getPageNumber (): number {
    let str = window.location.pathname

    // Remove trailing slash, if it exists
    str = (str.charAt(str.length - 1) === '/') ? str.substring(0, str.length - 1) : str

    const pathnameParts = str.split('/')

    if (pathnameParts.length < 1) {
      return 1
    }

    const lastElement = pathnameParts[pathnameParts.length - 1]
    const pageNumber = parseInt(lastElement)

    if (isNaN(pageNumber)) {
      return 1
    }

    return pageNumber
  }

  private convertPageNumberToPathParamString (pathPrefix: string, pageNumber: number): string {
    if (pageNumber < 2) {
      return ''
    }
    const slashBetweenUrlPrefixAndPageNumber = pathPrefix?.endsWith('/') ? '' : '/'
    const slashAfterPageNumber = pathPrefix?.endsWith('/') ? '/' : ''
    return `${slashBetweenUrlPrefixAndPageNumber}${pageNumber}${slashAfterPageNumber}`
  }

  public convertSearchParamsToHashParamsString (searchParams: SearchParams): string {
    const urlParams = new URLSearchParams()

    for (const key in searchParams) {
      // Skip if the property is from prototype
      if (!Object.prototype.hasOwnProperty.call(searchParams, key)) {
        continue
      }

      if (!Array.isArray(searchParams[key])) {
        if (urlParams.has(key, searchParams[key])) {
          continue
        }
        urlParams.append(key, searchParams[key])
        continue
      }

      for (const theArrayValue of searchParams[key]) {
        if (urlParams.has(key + '[]', theArrayValue)) {
          continue
        }
        urlParams.append(key + '[]', theArrayValue || '')
      }
    }

    let hash = ''
    if ([...urlParams].length > 0) {
      hash = urlParams.toString()
    }

    return (hash !== '') ? ('#' + hash) : ''
  }
}

export default UrlHashSearchParams
