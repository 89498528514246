import ExampleModule from '~/repositories/gtm/modules/example'
import ContentModule from '~/repositories/gtm/modules/content'
import SearchRequestsModule from '~/repositories/gtm/modules/searchRequests'
import SearchResultsModule from '~/repositories/gtm/modules/searchResults'
import InquiriesModule from '~/repositories/gtm/modules/inquiries'
import ProductsModule from '~/repositories/gtm/modules/products'
import PricesModule from '~/repositories/gtm/modules/prices'
import MapsModule from '~/repositories/gtm/modules/maps'
import FormsModule from '~/repositories/gtm/modules/forms'
import DatepickerModule from '~/repositories/gtm/modules/datepicker'
import FiltersModule from '~/repositories/gtm/modules/filters'

interface IBackendInstance {
  example: ExampleModule
  content: ContentModule
  searchRequests: SearchRequestsModule
  searchResults: SearchResultsModule,
  inquiriesModule: InquiriesModule,
  productsModule: ProductsModule,
  pricesModule: PricesModule,
  mapsModule: MapsModule,
  formsModule: FormsModule,
  datepickerModule: DatepickerModule,
  filtersModule: FiltersModule
}

// export default defineNuxtPlugin((nuxtApp) => {
export default defineNuxtPlugin(() => {
  // An object containing all repositories we need to expose
  const modules: IBackendInstance = {
    example: new ExampleModule(),
    content: new ContentModule(),
    searchRequests: new SearchRequestsModule(),
    searchResults: new SearchResultsModule(),
    inquiriesModule: new InquiriesModule(),
    productsModule: new ProductsModule(),
    pricesModule: new PricesModule(),
    mapsModule: new MapsModule(),
    formsModule: new FormsModule(),
    datepickerModule: new DatepickerModule(),
    filtersModule: new FiltersModule()
  }

  return {
    provide: {
      gtm: modules
    }
  }
})
