import { useGtm } from '@gtm-support/vue-gtm'

class MapsModule {
  sendMapEnterFullscreenEvent (): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    gtm.trackEvent({
      event: 'map',
      map_interaction: 'view_fullscreen'
    })
  }

  sendMapExitFullscreenEvent (): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    gtm.trackEvent({
      event: 'map',
      map_interaction: 'exit_fullscreen'
    })
  }

  sendMapVacationRentalClickEvent (): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    gtm.trackEvent({
      event: 'map',
      map_interaction: 'vacation_rental'
    })
  }

  sendMapClickEvent (): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    gtm.trackEvent({
      event: 'map',
      map_interaction: 'click'
    })
  }

  sendMapZoomInEvent (): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    gtm.trackEvent({
      event: 'map',
      map_interaction: 'zoom_in'
    })
  }

  sendMapZoomOutEvent (): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    gtm.trackEvent({
      event: 'map',
      map_interaction: 'zoom_out'
    })
  }
}

export default MapsModule
