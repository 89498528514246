const useHost = () => {
  const host = useState<string|null>(() => null)

  if (typeof host.value === 'string') {
    return host.value
  }

  const url: URL = useRequestURL()

  // currentUrl.value = url.href // https://example.com:3000/hello-world
  // protocol.value = url.protocol // https:
  host.value = url.host // example.com:3000
  // hostname.value = url.hostname // example.com
  // pathname.value = url.pathname // /hello-world

  return host.value
}

export default useHost
