import HttpFactory from '../HttpFactory'
// import IBasicArrayResponse from '../interfaces/IBasicArrayResponse'
import IBasicObjectResponse from '../interfaces/IBasicObjectResponse'

class ProductsModule extends HttpFactory {
  private RESOURCE = 'api/products'

  async getProductById (productId: string) {
    return await this.callUseAsyncData<IBasicObjectResponse>(
      'GET',
      `${this.RESOURCE}/${productId}`
    )
  }
}

export default ProductsModule
