<template>
  <template v-if="show">
    <slot />
  </template>
</template>

<script setup>
const stage = useState('state', () => process.env.ZO_ENVIRONMENT_STAGE)
const show = ['LOCAL', 'TEST'].includes(stage.value)
</script>
