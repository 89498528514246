import revive_payload_client_4sVQNw7RlN from "/portal-nuxt-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/portal-nuxt-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/portal-nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/portal-nuxt-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/portal-nuxt-app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/portal-nuxt-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/portal-nuxt-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/portal-nuxt-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/portal-nuxt-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/portal-nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_6wEQMY3tee from "/portal-nuxt-app/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import _01_backendRepositoryInitialization_XvmDJ03hKz from "/portal-nuxt-app/plugins/01.backendRepositoryInitialization.ts";
import _02_gtm_client_JmKh95VJnc from "/portal-nuxt-app/plugins/02.gtm.client.ts";
import _03_gtmRepositoryInitialization_client_a24tZQiiuq from "/portal-nuxt-app/plugins/03.gtmRepositoryInitialization.client.ts";
import jsonLd_L6MwRlvSUk from "/portal-nuxt-app/plugins/jsonLd.ts";
import searchParamsWatcher_client_DJulJmVw90 from "/portal-nuxt-app/plugins/searchParamsWatcher.client.ts";
import wishListVisibilityChangeListener_client_DwjqWQOLNO from "/portal-nuxt-app/plugins/wishListVisibilityChangeListener.client.ts";
import wishListWatcher_client_ET9RBdonjC from "/portal-nuxt-app/plugins/wishListWatcher.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_6wEQMY3tee,
  _01_backendRepositoryInitialization_XvmDJ03hKz,
  _02_gtm_client_JmKh95VJnc,
  _03_gtmRepositoryInitialization_client_a24tZQiiuq,
  jsonLd_L6MwRlvSUk,
  searchParamsWatcher_client_DJulJmVw90,
  wishListVisibilityChangeListener_client_DwjqWQOLNO,
  wishListWatcher_client_ET9RBdonjC
]