import { useGtm } from '@gtm-support/vue-gtm'

class SearchResultsModule {
  sendNoSearchResultsExclusiveEvent (): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }

    gtm.trackEvent({
      event: 'GAEvent',
      eventCategory: 'Suchergebnis',
      eventAction: 'Suchergebnis exklusiv',
      eventLabel: 'kein Suchergebnis'
    })
  }

  sendNoSearchResultsDynamicEvent (): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }

    gtm.trackEvent({
      event: 'GAEvent',
      eventCategory: 'Suchergebnis',
      eventAction: 'Suchergebnis dynamisch',
      eventLabel: 'kein Suchergebnis'
    })
  }

  sendNoSearchResultsLastMinuteEvent (): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }

    gtm.trackEvent({
      event: 'GAEvent',
      eventCategory: 'Suchergebnis',
      eventAction: 'LastMinute',
      eventLabel: 'kein Suchergebnis'
    })
  }
}

export default SearchResultsModule
