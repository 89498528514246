import { useGtm } from '@gtm-support/vue-gtm'

class FormsModule {
  sendNewsletterExistingEmailEnteredEvent (eventLabel: string): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    gtm.trackEvent({
      event: 'Formular-Tracking',
      eventCategory: 'Newsletter-Formulare',
      eventAction: 'Vorhandene E-Mail eingetragen',
      eventLabel
    })
  }

  sendNewsletterIncorrectEmailEnteredEvent (eventLabel: string): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    gtm.trackEvent({
      event: 'Formular-Tracking',
      eventCategory: 'Newsletter-Formulare',
      eventAction: 'Fehlerhafte E-Mail eingetragen',
      eventLabel
    })
  }

  sendNewsletterFormSentEvent (eventLabel: string): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    gtm.trackEvent({
      event: 'Formular-Tracking',
      eventCategory: 'Newsletter-Formulare',
      eventAction: 'abgeschickt',
      eventLabel
    })
  }

  sendNewsletterFormConfirmationEmailSentEvent (eventLabel: string): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    gtm.trackEvent({
      event: 'Formular-Tracking',
      eventCategory: 'Newsletter-Formulare',
      eventAction: 'Bestätigungsmail wurde verschickt',
      eventLabel
    })
  }

  sendNewsletterUabCheckMarkSetEvent (): string {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    gtm.trackEvent({
      event: 'Formular-Tracking',
      eventCategory: 'Newsletter-Formulare',
      eventAction: 'Newsletter-Häkchen gesetzt',
      eventLabel: 'Newsletter-Anmeldung UAB'
    })
  }
}

export default FormsModule
