import { useGtm } from '@gtm-support/vue-gtm'

class ExampleModule {
  sendAnExampleEvent (eventLabel = 'My custom component trigger') {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }

    gtm.trackEvent({
      event: 'My example event',
      category: 'category',
      action: 'click',
      label: eventLabel,
      value: 5000,
      noninteraction: false
    })
  }
}

export default ExampleModule
