import { useGtm } from '@gtm-support/vue-gtm'

class PricesModule {
  sendPriceCalculationFailureEvent (
    preisFehler: string
  ): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    gtm.trackEvent({
      event: 'preisFehler',
      preisFehler
    })
  }

  sendPriceCalculationCalculatedEvent (
    arrivalDate: string,
    departureDate: string,
    totalPrice: string,
    pricePerNight: string
  ): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    gtm.trackEvent({
      event: 'preisBerechnet',
      preisBerechnet: 'Preis wurde berechnet!',
      anreisedatumDE: arrivalDate,
      anreisedatumUS: this.parseDate(arrivalDate),
      abreisedatumDE: departureDate,
      abreisedatumUS: this.parseDate(departureDate),
      reiseGesamtpreisDE: totalPrice,
      reiseGesamtpreisUS: (totalPrice).toString().replace('.', ','),
      gesamtpreisNachtDE: pricePerNight,
      gesamtpreisNachtUS: (pricePerNight).toString().replace('.', ',')
    })
  }

  private parseDate (date: string): string {
    if (!date) {
      return ''
    }
    const dateParts = date.split('.')
    const day = dateParts[0]
    const month = dateParts[1]
    const year = dateParts[2]
    const dateObject = new Date(year, month - 1, day)
    return dateObject.toLocaleDateString('en-US')
  }
}

export default PricesModule
