import type SearchParams from '~/types/search/SearchParams'

class SearchParamsHelper {
  public static areSearchParamsObjectsIdentical (searchParams1: SearchParams, searchParams2: SearchParams): boolean {
    for (const key1 in searchParams1) {
      if (!Object.prototype.hasOwnProperty.call(searchParams2, key1)) {
        return false
      }
      if (!SearchParamsHelper.areValuesIdentical(searchParams1[key1], searchParams2[key1], key1)) {
        return false
      }
    }
    for (const key2 in searchParams2) {
      if (!Object.prototype.hasOwnProperty.call(searchParams1, key2)) {
        return false
      }
      if (!SearchParamsHelper.areValuesIdentical(searchParams1[key2], searchParams2[key2], key2)) {
        return false
      }
    }

    return true
  }

  private static areValuesIdentical (val1: any, val2: any, key: string): boolean {
    // Check if both values are primitives or both values are arrays
    if (
      (Array.isArray(val1) && !Array.isArray(val2)) ||
      (!Array.isArray(val1) && Array.isArray(val2))
    ) {
      return false
    }

    // Check if the primitive values are identical
    if (!Array.isArray(val1) && !Array.isArray(val2) && val1 !== val2) {
      return false
    }

    if (!Array.isArray(val1) || !Array.isArray(val2)) {
      return true
    }

    if (key === 'ausstattung') {
      val1 = val1.map(Number)
      val2 = val2.map(Number)
    }

    // Check if the arrays are identical
    const diff1 = val1.filter(x => !val2.includes(x))
    const diff2 = val2.filter(x => !val1.includes(x))
    if (diff1.length > 0 || diff2.length > 0) {
      return false
    }

    return true
  }
}

export default SearchParamsHelper
