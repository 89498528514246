<template>
  <h1 class="text-4xl p-5">
    {{ t('title') }}
  </h1>
  <p class="text-2xl p-5">
    {{ t('body') }}
  </p>
</template>

<script setup>
const { translate: t } = useTranslations('ErrorPage404')
</script>
