import { useGtm } from '@gtm-support/vue-gtm'

class ProductsModule {
  sendProductImpressionEvent (
    products: [],
    guests: string,
    arrivalDate: string,
    departureDate: string
  ): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    const infoProducts = []
    products.forEach((product) => {
      infoProducts.push(this.getProduct(product, '', guests, arrivalDate, departureDate))
    })
    gtm.trackEvent({
      event: 'productImpression',
      ecommerce: {
        impressions: infoProducts
      }
    })
  }

  sendProductDetailViewEvent (
    product: object,
    pricePerNight: string,
    guests: string,
    arrivalDate: string,
    departureDate: string
  ): void {
    const infoProduct = this.getProduct(product, pricePerNight, guests, arrivalDate, departureDate)
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    gtm.trackEvent({
      event: 'productDetailView',
      ecommerce: {
        detail: {
          products: [infoProduct]
        }
      }
    })
  }

  sendProductClickEvent (
    product: any,
    guests: string,
    arrivalDate: string,
    departureDate: string
  ): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    const infoProduct = this.getProduct(product, '', guests, arrivalDate, departureDate)
    gtm.trackEvent({
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: { list: 'Suchergebnis dynamisch Liste' },
          products: [infoProduct]
        }
      }
    })
  }

  private getProduct (
    product: object,
    pricePerNight: string,
    guests: string,
    arrivalDate: string,
    departureDate: string
  ) {
    const startDate = this.parseDate(arrivalDate)
    const endDate = this.parseDate(departureDate)

    const timeDifference = endDate.getTime() - startDate.getTime()

    let flexNights = timeDifference / (1000 * 3600 * 24)
    flexNights = Math.round(flexNights)

    const info = {
      id: product?.nr,
      name: product?.hausname,
      category: product?.haustyp,
      dimension1: product?.user_nr,
      dimension2: arrivalDate,
      dimension3: departureDate,
      dimension5: guests,
      dimension6: flexNights,
      dimension7: product?.address?.ort ?? product?.ortsname,
      dimension8: product?.buchungssystem,
      price: pricePerNight !== '' ? pricePerNight : product.preis_min
    }
    if (arrivalDate === '') {
      delete info.dimension2
      delete info.dimension3
      delete info.dimension5
      delete info.dimension6
    }
    return info
  }

  private parseDate (str) {
    const parts = str.split('.')
    return new Date(parts[2], parts[1] - 1, parts[0])
  }
}

export default ProductsModule
