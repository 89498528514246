import { useGtm } from '@gtm-support/vue-gtm'

class InquiriesModule {
  sendRecommendationImpressionEvent (
    promotions: []
  ): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    gtm.trackEvent({
      event: 'recommendation_impression',
      ecommerce: {
        promoView: {
          promotions
        }
      }
    })
  }

  sendPurchaseEvent (
    actionFieldId: number,
    totalPrice: number,
    email: string,
    objectId: number,
    houseName: string,
    houseType: string,
    userId: number,
    arrival: string,
    departure: string,
    personen: number,
    nights: number,
    ort: string
  ): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    gtm.trackEvent({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: actionFieldId,
            revenue: totalPrice,
            purchase_mail: email
          },
          products: [{
            id: objectId,
            name: houseName,
            category: houseType,
            mietpreis: totalPrice,
            quantity: 1,
            dimension1: userId,
            dimension2: arrival,
            dimension3: departure,
            dimension5: personen,
            dimension6: nights,
            dimension7: ort,
            dimension8: 'unverbindliche Anfragebuchung',
            type: 'inquiry'
          }]
        }
      }
    })
  }

  sendPromotionClickEvent (
    promotions: []
  ): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }
    gtm.trackEvent({
      event: 'promotionClick',
      ecommerce: {
        promoClick: {
          promotions
        }
      }
    })
  }
}

export default InquiriesModule
