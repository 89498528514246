import { createGtm } from '@gtm-support/vue-gtm'
import { type VueGtmUseOptions } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  const { currentPortal } = usePortals()
  const GtmId = currentPortal.value.portal.tenant === 'hpve'
    ? 'GTM-5TKZ88J' // harz-travel.de and sauerland-travel.de
    : 'GTM-5FXF6X' // Travanto and regional portals

  const gtmOptions = {
    LOCAL: {
      googleTagManagerId: 'GTM-0',
      googleTagManagerEnabled: true,
      googleTagManagerDebug: true
    },
    TEST: {
      googleTagManagerId: 'GTM-0',
      googleTagManagerEnabled: true,
      googleTagManagerDebug: true
    },
    STAGING: {
      googleTagManagerId: 'GTM-0',
      googleTagManagerEnabled: true,
      googleTagManagerDebug: true
    },
    PRODUCTION: {
      googleTagManagerId: GtmId,
      googleTagManagerEnabled: true,
      googleTagManagerDebug: false
    }
  }

  if (!Object.prototype.hasOwnProperty.call(gtmOptions, config.public.nuxtEnvironmentStage)) {
    return
  }

  nuxtApp.vueApp.use(createGtm({
    id: gtmOptions[config.public.nuxtEnvironmentStage].googleTagManagerId,
    defer: false,
    compatibility: false,
    enabled: gtmOptions[config.public.nuxtEnvironmentStage].googleTagManagerEnabled,
    debug: gtmOptions[config.public.nuxtEnvironmentStage].googleTagManagerDebug,
    loadScript: true,
    vueRouter: useRouter() as VueGtmUseOptions['vueRouter'],
    trackOnNextTick: false
  }))
})
