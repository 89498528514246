import { default as _91_46_46_46slug_93m8jCvkRxUyMeta } from "/portal-nuxt-app/pages/[...slug].vue?macro=true";
import { default as counterWDYks9wqQGMeta } from "/portal-nuxt-app/pages/dev-playground/counter.vue?macro=true";
import { default as page11m2FxYGUOhMeta } from "/portal-nuxt-app/pages/dev-playground/page1.vue?macro=true";
import { default as page2Ic9V7eOD89Meta } from "/portal-nuxt-app/pages/dev-playground/page2.vue?macro=true";
import { default as pageProductgk0KFai0XiMeta } from "/portal-nuxt-app/pages/dev-playground/pageProduct.vue?macro=true";
import { default as pageRueseUHzjDB1hXMeta } from "/portal-nuxt-app/pages/dev-playground/pageRues.vue?macro=true";
import { default as prerenderedpCvmpe3UOqMeta } from "/portal-nuxt-app/pages/dev-playground/prerendered.vue?macro=true";
import { default as tailwindTgUEiaRSVrMeta } from "/portal-nuxt-app/pages/dev-playground/tailwind.vue?macro=true";
import { default as _91currentPage_93L1JIfprU4HMeta } from "/portal-nuxt-app/pages/lastminute/[currentPage].vue?macro=true";
import { default as indexIZnA2ARLg5Meta } from "/portal-nuxt-app/pages/lastminute/index.vue?macro=true";
import { default as _91key_93ka7Y4mmj2UMeta } from "/portal-nuxt-app/pages/newsletter/[key].vue?macro=true";
import { default as indexjTx1HFU6yxMeta } from "/portal-nuxt-app/pages/newsletter/index.vue?macro=true";
import { default as indexzBweEHamIjMeta } from "/portal-nuxt-app/pages/objektbewertung/index.vue?macro=true";
import { default as _91currentPage_93awce2jZa7QMeta } from "/portal-nuxt-app/pages/search/[currentPage].vue?macro=true";
import { default as indexTrOuDe6lVLMeta } from "/portal-nuxt-app/pages/search/index.vue?macro=true";
import { default as _91key_93GRDuMyvnOQMeta } from "/portal-nuxt-app/pages/wishlist/[key].vue?macro=true";
import { default as indexaWV6cvgy65Meta } from "/portal-nuxt-app/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/portal-nuxt-app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "dev-playground-counter",
    path: "/dev-playground/counter",
    component: () => import("/portal-nuxt-app/pages/dev-playground/counter.vue").then(m => m.default || m)
  },
  {
    name: "dev-playground-page1",
    path: "/dev-playground/page1",
    component: () => import("/portal-nuxt-app/pages/dev-playground/page1.vue").then(m => m.default || m)
  },
  {
    name: "dev-playground-page2",
    path: "/dev-playground/page2",
    meta: page2Ic9V7eOD89Meta || {},
    component: () => import("/portal-nuxt-app/pages/dev-playground/page2.vue").then(m => m.default || m)
  },
  {
    name: "dev-playground-pageProduct",
    path: "/dev-playground/pageProduct",
    component: () => import("/portal-nuxt-app/pages/dev-playground/pageProduct.vue").then(m => m.default || m)
  },
  {
    name: "dev-playground-pageRues",
    path: "/dev-playground/pageRues",
    component: () => import("/portal-nuxt-app/pages/dev-playground/pageRues.vue").then(m => m.default || m)
  },
  {
    name: "dev-playground-prerendered",
    path: "/dev-playground/prerendered",
    component: () => import("/portal-nuxt-app/pages/dev-playground/prerendered.vue").then(m => m.default || m)
  },
  {
    name: "dev-playground-tailwind",
    path: "/dev-playground/tailwind",
    component: () => import("/portal-nuxt-app/pages/dev-playground/tailwind.vue").then(m => m.default || m)
  },
  {
    name: "lastminute-currentPage",
    path: "/lastminute/:currentPage()",
    meta: _91currentPage_93L1JIfprU4HMeta || {},
    component: () => import("/portal-nuxt-app/pages/lastminute/[currentPage].vue").then(m => m.default || m)
  },
  {
    name: "lastminute",
    path: "/lastminute",
    component: () => import("/portal-nuxt-app/pages/lastminute/index.vue").then(m => m.default || m)
  },
  {
    name: "newsletter-key",
    path: "/newsletter/:key()",
    component: () => import("/portal-nuxt-app/pages/newsletter/[key].vue").then(m => m.default || m)
  },
  {
    name: "newsletter",
    path: "/newsletter",
    component: () => import("/portal-nuxt-app/pages/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "objektbewertung",
    path: "/objektbewertung",
    component: () => import("/portal-nuxt-app/pages/objektbewertung/index.vue").then(m => m.default || m)
  },
  {
    name: "search-currentPage",
    path: "/search/:currentPage()",
    meta: _91currentPage_93awce2jZa7QMeta || {},
    component: () => import("/portal-nuxt-app/pages/search/[currentPage].vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/portal-nuxt-app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "wishlist-key",
    path: "/wishlist/:key()",
    component: () => import("/portal-nuxt-app/pages/wishlist/[key].vue").then(m => m.default || m)
  },
  {
    name: "wishlist",
    path: "/wishlist",
    component: () => import("/portal-nuxt-app/pages/wishlist/index.vue").then(m => m.default || m)
  }
]