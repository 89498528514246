import HttpFactory from '../HttpFactory'
// import IBasicArrayResponse from '../interfaces/IBasicArrayResponse'
import IBasicObjectResponse from '../interfaces/IBasicObjectResponse'
import SearchParams from '../../../types/search/SearchParams'

class SearchModule extends HttpFactory {
  private RESOURCE = 'services/search'

  async search (searchParams: SearchParams) {
    return await this.callUseFetch<IBasicObjectResponse>(
      'POST',
      `${this.RESOURCE}`,
      {
        params: searchParams
      },
      {},
      {
        watch: false
      }
    )
  }

  async searchFromClient (searchParams: SearchParams) {
    return await this.callFetch<IBasicObjectResponse>(
      'POST',
      `${this.RESOURCE}`,
      {
        params: searchParams
      }
    )
  }

  async getSearchForm () {
    return await this.callFetch<IBasicObjectResponse>(
      'GET',
      `${this.RESOURCE}/form`
    )
  }
}

export default SearchModule
