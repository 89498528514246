const useBackend = () => {
  const config = useRuntimeConfig()
  let baseUrl = `https://${useHost()}/backend/`
  if (
    import.meta.server &&
    (
      config.public.nuxtEnvironmentStage === 'LOCAL' ||
      config.public.nuxtEnvironmentStage === 'TEST'
    )
  ) {
    baseUrl = 'http://portal-backend/backend/'
  }

  const apiUrl = baseUrl + 'api/'
  const formsUrl = baseUrl + 'forms/'
  const servicesUrl = baseUrl + 'services/'

  return {
    baseUrl,
    apiUrl,
    formsUrl,
    servicesUrl
  }
}

// export { useBackend }
export default useBackend
