import HttpFactory from '../HttpFactory'
import IBasicArrayResponse from '../interfaces/IBasicArrayResponse'

class RegionalAutocomplete extends HttpFactory {
  private RESOURCE = 'api/regional-autocomplete'
  async get () {
    return await this.callUseAsyncData<IBasicArrayResponse>(
      'GET',
      `${this.RESOURCE}`
    )
  }
}

export default RegionalAutocomplete
