import { defineNuxtPlugin } from '#app/nuxt'
import { LazyContentPageType, LazyErrorPageType, LazyFaqPageType, LazyKontaktPageType, LazyLegalPageType, LazyPhotoCreditsPageType, LazyProductPageType, LazyRedirectPageType, LazySearchPageType, LazySitemapPageType } from '#components'
const lazyGlobalComponents = [
  ["ContentPageType", LazyContentPageType],
["ErrorPageType", LazyErrorPageType],
["FaqPageType", LazyFaqPageType],
["KontaktPageType", LazyKontaktPageType],
["LegalPageType", LazyLegalPageType],
["PhotoCreditsPageType", LazyPhotoCreditsPageType],
["ProductPageType", LazyProductPageType],
["RedirectPageType", LazyRedirectPageType],
["SearchPageType", LazySearchPageType],
["SitemapPageType", LazySitemapPageType],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
