const useTranslations = (domain: string) => {
  const defaultDomain = domain

  const translations = useState<object|null>(() => null)

  const loadTranslations = async () => {
    if (translations.value !== null) {
      return
    }

    const { $backend } = useNuxtApp()
    const { data: translationsData } = await $backend.translations.getAllTranslations()

    translations.value = translationsData.value.data
  }

  const translate = computed(() =>
    (...args: any[]) => {
      let key, optionalDomain, variables

      if (typeof args[1] === 'string') {
        [key, optionalDomain = null, variables = null] = args
      } else {
        [key, variables = null] = args
      }

      domain = optionalDomain || defaultDomain

      let text = translations.value?.[domain]?.[key]

      if (import.meta.client && translations.value && !text) {
        console.warn(`Missing language placeholder:\n${domain} -> ${key}\nhttps://seoadmin.localhost/admin/i18n/domains?domain=${domain}&lang=de`); // eslint-disable-line no-console, semi
      }

      if (text && variables) {
        text = text.replace(/\${(\w+)}/g, (match, name) => {
          return variables[name] ?? match
        })
      }

      return text
    }
  )

  const numerus = nr => parseInt(nr) === 1 ? 'singular' : 'plural'

  return {
    loadTranslations,
    translate,
    numerus
  }
}

export default useTranslations
