import { useWishListStore } from '~/stores/wishList'
import { WebStorage } from '~/utils/WebStorage'

export default defineNuxtPlugin(() => {
  const webStorage = new WebStorage()
  const wishListStore = useWishListStore()

  // Track wishlist store state changes and persist them to WebStorage
  watch(
    wishListStore.$state,
    (state) => {
      const itemsForWebStorage = {}
      for (const objektNr of state.items) {
        itemsForWebStorage['obj_' + objektNr] = Math.round(Date.now() / 1000)
      }
      webStorage.items.WishList.set(itemsForWebStorage)
      webStorage.items.WishList_ShareKey.set(state.share_key)
    },
    { deep: true }
  )
})
