import { useGtm } from '@gtm-support/vue-gtm'

class SearchRequestsModule {
  sendSearchRequestEvent (
    location: string,
    locationName: string|null = null,
    searchTrigger: string|null = null
  ): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }

    const eventData = {
      event: 'suchanfrage',
      kategorie: 'suche',
      ort: location
    }
    if (locationName !== null) {
      eventData.ort_name = locationName
    } else {
      eventData.ort_name = location
    }
    if (searchTrigger !== null) {
      eventData.search_trigger = searchTrigger
    }

    gtm.trackEvent(eventData)
  }
}

export default SearchRequestsModule
