import HttpFactory from '../HttpFactory'
// import IBasicArrayResponse from '../interfaces/IBasicArrayResponse'
import IBasicObjectResponse from '../interfaces/IBasicObjectResponse'

class WishListSharesModule extends HttpFactory {
  private RESOURCE = 'api/wish-list-shares'

  async getWishListShareByKey (key: string) {
    return await this.callUseAsyncData<IBasicObjectResponse>(
      'GET',
      `${this.RESOURCE}/${key}`
    )
  }

  async getWishListShareByKeyFromClient (key: string) {
    return await this.callFetch<IBasicObjectResponse>(
      'GET',
      `${this.RESOURCE}/${key}`
    )
  }

  async createWishListShareFromClient (objectIds: number[]) {
    return await this.callFetch<IBasicObjectResponse>(
      'PUT',
      `${this.RESOURCE}`,
      {
        object_ids: objectIds
      }
    )
  }

  async updateWishListShareFromClient (key: string, objectIds: number[]) {
    return await this.callFetch<IBasicObjectResponse>(
      'PUT',
      `${this.RESOURCE}/${key}`,
      {
        object_ids: objectIds
      }
    )
  }
}

export default WishListSharesModule
