import { useWishListStore } from '~/stores/wishList'

export default defineNuxtPlugin(() => {
  const wishListStore = useWishListStore()

  // Track changes from another tab using the Page Visibility API
  const handleVisibilityChange = function () {
    if (document.visibilityState === 'hidden') {
      return
    }
    // $reset initializes the store again, thus gets the items from WebStorage
    wishListStore.$reset()
  }
  document.addEventListener(
    'visibilitychange',
    handleVisibilityChange,
    false
  )
})
