import HttpFactory from '../HttpFactory'
import IBasicArrayResponse from '../interfaces/IBasicArrayResponse'
import IBasicObjectResponse from '../interfaces/IBasicObjectResponse'

class PortalsModule extends HttpFactory {
  private RESOURCE = 'api/portals'

  async getAllPortals () {
    return await this.callUseAsyncData<IBasicArrayResponse>(
      'GET',
      `${this.RESOURCE}`
    )
  }

  async getCurrentPortal () {
    return await this.callUseAsyncData<IBasicObjectResponse>(
      'GET',
      `${this.RESOURCE}/current`
    )
  }

  async getPortalById (portalId: string) {
    return await this.callUseAsyncData<IBasicObjectResponse>(
      'GET',
      `${this.RESOURCE}/${portalId}`
    )
  }
}

export default PortalsModule
