import HttpFactory from '../HttpFactory'
// import IBasicArrayResponse from '../interfaces/IBasicArrayResponse'
import IBasicObjectResponse from '../interfaces/IBasicObjectResponse'

class NewsletterEntriesModule extends HttpFactory {
  private FORM = 'forms/newsletter'
  private RESOURCE = 'api/newsletter-entries'

  async createNewsletterEntryFromClient (data: FormData) {
    return await this.callFetch<IBasicObjectResponse>(
      'POST',
      `${this.FORM}`,
      data
    )
  }

  async confirmNewsletterEntry (key: string) {
    return await this.callUseAsyncData<IBasicObjectResponse>(
      'POST',
      `${this.RESOURCE}/${key}/confirm`
    )
  }
}

export default NewsletterEntriesModule
