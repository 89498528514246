import { defineStore } from 'pinia'
import { WebStorage } from '~~/utils/WebStorage'

export const useWishListStore = defineStore('wish_list', {
  state: () => {
    // State initialization
    const webStorage = new WebStorage()

    // Get items from WebStorage
    const itemsFromWebStorage = webStorage.items.WishList.get()
    const items = [] as number[]
    if (itemsFromWebStorage) {
      for (const prop in itemsFromWebStorage) {
        const itemNumber = Number(
          prop.replace('obj_', '')
        )
        items.push(itemNumber)
      }
    }

    // Get share_key from WebStorage
    const shareKeyFromWebStorage = webStorage.items.WishList_ShareKey.get()
    let shareKey = '' as string
    if (shareKeyFromWebStorage && shareKeyFromWebStorage.length > 0) {
      shareKey = shareKeyFromWebStorage
    }

    return {
      items: items as number[],
      share_key: shareKey as string
    }
  },
  getters: {
    itemsCount: function (state): number {
      return state.items?.length || 0
    },
    statusOfObjectInWishList: function (state) {
      return (objectNr: number): boolean => {
        return state.items.find(
          itemObjectNr => itemObjectNr === objectNr
        ) > 0
      }
    }
  },
  actions: {
    AddOrRemoveObjectInWishListAndGetStatus (objectNr: number): boolean {
      if (objectNr == null) {
        return false
      }
      if (this.items.includes(objectNr)) {
        const index = this.items.indexOf(objectNr)
        this.items.splice(index, 1)
        return false
      }
      this.items.push(objectNr)
      return true
    },
    removeObjectFromWishList (objectNr: number): void {
      if (!this.items.includes(objectNr)) {
        return
      }
      const index = this.items.indexOf(objectNr)
      this.items.splice(index, 1)
    }
  }
})
