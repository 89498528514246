import { useGtm } from '@gtm-support/vue-gtm'

class FiltersModule {
  sendRatingFilterAddEvent (): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }

    const eventData = {
      event: 'filter',
      eventCategory: 'filter',
      eventAction: 'Klick',
      eventLabel: 'gaestebewertung'
    }

    gtm.trackEvent(eventData)
  }

  sendRatingFilterRemoveEvent (bewertung: string): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }

    const eventData = {
      event: 'filter',
      eventCategory: 'filter',
      eventAction: 'klick_entfernen',
      eventLabel: 'gaestebewertung_' + bewertung
    }

    gtm.trackEvent(eventData)
  }
}
export default FiltersModule
