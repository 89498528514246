<template>
  <h1 class="text-4xl p-5">
    Error {{ props.error?.statusCode }}
  </h1>
  <p class="text-2xl p-5">
    {{ props.error?.statusMessage }}
  </p>
  <p
    v-if="props.error?.message !== props.error?.statusMessage"
    class="text-2xl p-5"
  >
    {{ props.error?.message }}
  </p>
  <p class="text-2xl p-5">
    {{ props.error?.description }}
  </p>
</template>

<script setup>
const props = defineProps({
  error: {
    type: Object,
    default: null
  }
})
</script>
