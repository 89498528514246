import { useSearchParamsStore } from '~/stores/searchParams'
import { WebStorage } from '~/utils/WebStorage'
import UrlHashSearchParams from '~/utils/search/UrlHashSearchParams'

export default defineNuxtPlugin(() => {
  const webStorage = new WebStorage()
  const searchParamsStore = useSearchParamsStore()
  const route = useRoute()
  const urlHashSearchParams = new UrlHashSearchParams()

  // '$subscrible' runs before 'watch'
  // Here it works as an interceptor before 'watch' is triggered.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  searchParamsStore.$subscribe((mutation, state) => {
    if (state.force_page_change === false) {
      state.page = 0
    }
    state.force_page_change = false
  })

  // Track search params store state changes
  // and persist them to WebStorage and the url's hash part.
  watch(
    searchParamsStore.$state,
    (state) => {
      const cookieParams = useCookie(
        webStorage.items.SearchParams.getUseCookieKey(),
        webStorage.items.SearchParams.getUseCookieOptions()
      )
      cookieParams.value = webStorage.items.SearchParams.getUseCookieValue(state)

      if (route.path.startsWith('/search/') || route.path.startsWith('/lastminute/')) {
        urlHashSearchParams.set(searchParamsStore.$state.page + 1, searchParamsStore.activeFilters)
        return
      }

      if (searchParamsStore.$state.is_static_page) {
        window.location.href = '/search/' + urlHashSearchParams.convertSearchParamsToHashParamsString(searchParamsStore.activeFilters)
      }
    },
    { deep: true }
  )
})
