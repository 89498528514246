import HttpFactory from '../HttpFactory'
import IBasicArrayResponse from '../interfaces/IBasicArrayResponse'
// import IBasicObjectResponse from '../interfaces/IBasicObjectResponse'

class TranslationsModule extends HttpFactory {
  private RESOURCE = 'api/translations'

  async getAllTranslations () {
    return await this.callUseAsyncData<IBasicArrayResponse>(
      'GET',
      `${this.RESOURCE}`
    )
  }
}

export default TranslationsModule
