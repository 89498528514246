export interface StorageConfig {
  storage: 'localStorage' | 'sessionStorage' | 'cookie'
  type: 'string' | 'plainobject' | 'array'
  max_age: number,
  same_site?: 'Strict' | 'Lax' | 'None'
}

const config: { [key: string]: StorageConfig } = {
  WishList: { storage: 'localStorage', type: 'plainobject', max_age: 365 * 24 * 60 * 60 },
  WishList_ShareKey: { storage: 'localStorage', type: 'string', max_age: 365 * 24 * 60 * 60 },
  WishList_Filters: { storage: 'localStorage', type: 'plainobject', max_age: 365 * 24 * 60 * 60 },
  SearchParams: { storage: 'cookie', type: 'plainobject', max_age: 365 * 24 * 60 * 60, same_site: 'Strict' },
  InquiryForm: { storage: 'cookie', type: 'plainobject', max_age: 365 * 24 * 60 * 60 }
}

export class WebStorage {
  private storages = {
    localStorage: {
      getItem (key: string) {
        // const expires = JSON.parse(localStorage.getItem('expires') || '{}')
        // if (expires[key] < new Date().toISOString()) {
        // return null
        // }
        return localStorage.getItem(key)
      },
      setItem (key: string, value: string) {
        localStorage.setItem(key, value)
        const expires = JSON.parse(localStorage.getItem('expires') || '{}')
        expires[key] = new Date(new Date().getTime() + 12 * 1000).toISOString()
        localStorage.setItem('expires', JSON.stringify(expires))
      }
    },
    cookie: {
      getItem (key: string) {
        const myCookie = document.cookie.match(key + '=(.*?)(|$)')
        if (Array.isArray(myCookie)) {
          return decodeURIComponent(myCookie[1])
        }
      },
      setItem (key: string, value: string) {
        const maxAge = config[key].max_age || 365 * 24 * 60 * 60
        const sameSite = config[key].same_site || 'Strict'
        document.cookie = `${key}=${encodeURIComponent(value)};Path=/;Max-Age=${maxAge};SameSite=${sameSite}`
      }
    }
  }

  private datatypes = {
    string (storage: any, key: string) {
      return {
        set (data: string) {
          storage.setItem(key, data)
        },
        get () {
          const data = storage.getItem(key)
          return data
        }
      }
    },
    plainobject (storage: any, key: string) {
      return {
        set (data: object) {
          storage.setItem(key, JSON.stringify(data))
        },
        get () {
          if (!import.meta.client) { return 0 }
          if (storage.getItem(key) == null) {
            storage.setItem(key, JSON.stringify({}))
          }
          return JSON.parse(storage.getItem(key))
        },
        getUseCookieKey (): string {
          return key
        },
        getUseCookieOptions (): object {
          const maxAge = config[key].max_age || 365 * 24 * 60 * 60
          const sameSite = config[key].same_site || 'Strict'
          return {
            path: '/',
            maxAge,
            sameSite: sameSite.toLowerCase()
          }
        },
        getUseCookieValue (data: object): string {
          return JSON.stringify(data)
        },
        getProp (propKey: string) {
          return this.get()[propKey]
        },
        setProp (propKey: string, value: any) {
          const data = this.get()
          data[propKey] = value
          this.set(data)
        },
        deleteProp (propKey: string) {
          const data = this.get()
          delete data[propKey]
          this.set(data)
        }
      }
    },
    array (storage: any, key: string) {
      return {
        set (data: any[]) {
          if (!Array.isArray(data)) {
            try {
              throw new Error('Must be an array')
            } catch (error) {
              alert('Must be an array')
            }
          }
          storage.setItem(key, JSON.stringify(data))
        },
        get () {
          return JSON.parse(storage.getItem(key) || '[]')
        },
        add (value: any) {
          const arr = this.get()
          arr.push(value)
          this.set(arr)
        },
        addOnce (value: any) {
          if (!this.get().includes(value)) {
            this.add(value)
          }
        },
        remove (value: any) {
          const arr = this.get()
          const index = arr.indexOf(value)
          if (index !== -1) {
            arr.splice(index, 1)
          }
          this.set(arr)
        }
      }
    }
  }

  public items: { [key: string]: any }

  constructor () {
    this.items = Object.keys(config).reduce((acc: any, key: string) => {
      acc[key] = this.datatypes[config[key].type](this.storages[config[key].storage], key)
      return acc
    }, {})
  }
}
