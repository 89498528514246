import { AsyncDataOptions } from 'nuxt/app'
import HttpFactory from '../HttpFactory'
// import IBasicArrayResponse from '../interfaces/IBasicArrayResponse'
import IBasicObjectResponse from '../interfaces/IBasicObjectResponse'

class PagesModule extends HttpFactory {
  private RESOURCE = 'api/pages'

  // https://nuxt.com/docs/api/composables/use-route#api
  // Using route.fullPath in your template can trigger hydration issues

  async getPageByUrlPath (urlPath: string, urlFullPath: string) {
    const asyncDataOptions: AsyncDataOptions<IBasicObjectResponse> = {
      transform: (data) => {
        if (typeof data === 'string') {
          try {
            data = JSON.parse(data)
          } catch (e) {
            throw new Error(`Error in "${import.meta.url}" after calling "${this.RESOURCE}?path=${urlPath}"\n\n${e}\n\n${data}`, {
              cause: e
            })
          }
        }
        return data
      }
    }

    return await this.callUseAsyncData<IBasicObjectResponse>(
      'GET',
      `${this.RESOURCE}`,
      null,
      {
        'travanto-url-path': urlPath,
        'travanto-url-full-path': urlFullPath
      },
      {},
      asyncDataOptions
    )
  }

  async getPageByUrlPathFromClient (urlPath: string, urlFullPath: string) {
    const asyncDataOptions: AsyncDataOptions<IBasicObjectResponse> = {
      server: false,
      transform: (data) => {
        if (typeof data === 'string') {
          try {
            data = JSON.parse(data)
          } catch (e) {
            throw new Error(`Error in "${import.meta.url}" after calling "${this.RESOURCE}?path=${urlPath}"\n\n${e}\n\n${data}`, {
              cause: e
            })
          }
        }
        return data
      }
    }

    return await this.callUseAsyncData<IBasicObjectResponse>(
      'GET',
      `${this.RESOURCE}?client`,
      null,
      {
        'travanto-url-path': urlPath,
        'travanto-url-full-path': urlFullPath
      },
      {},
      asyncDataOptions
    )
  }
}

export default PagesModule
