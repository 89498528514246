import { $fetch, FetchOptions } from 'ofetch'

import PortalsModule from '~/repositories/backend/modules/portals'
import TranslationsModule from '~/repositories/backend/modules/translations'
import ProductsModule from '~/repositories/backend/modules/products'
import WishListSharesModule from '~/repositories/backend/modules/wishListShares'
import PagesModule from '~/repositories/backend/modules/pages'
import SearchModule from '~/repositories/backend/modules/search'
import AmenitiesModule from '~/repositories/backend/modules/amenities'
import RegionalAutocompleteModule from '~/repositories/backend/modules/regionalAutocomplete'
import NewsletterEntriesModule from '~/repositories/backend/modules/newsletterEntries'

interface IBackendInstance {
  portals: PortalsModule,
  translations: TranslationsModule,
  products: ProductsModule,
  wishListShares: WishListSharesModule,
  pages: PagesModule,
  search: SearchModule,
  amenities: AmenitiesModule,
  regionalAutocomplete: RegionalAutocompleteModule,
  newsletterEntries: NewsletterEntriesModule
}

// export default defineNuxtPlugin((nuxtApp) => {
export default defineNuxtPlugin(() => {
  // const host = useHost()
  const backend = useBackend()

  const fetchOptions: FetchOptions = {
    baseURL: backend.baseUrl
  }

  // Create a new instance of $fetcher with custom options
  const backendFetcher = $fetch.create(fetchOptions)

  // An object containing all repositories we need to expose
  const modules: IBackendInstance = {
    portals: new PortalsModule(backendFetcher),
    translations: new TranslationsModule(backendFetcher),
    products: new ProductsModule(backendFetcher),
    wishListShares: new WishListSharesModule(backendFetcher),
    pages: new PagesModule(backendFetcher),
    search: new SearchModule(backendFetcher),
    amenities: new AmenitiesModule(backendFetcher),
    regionalAutocomplete: new RegionalAutocompleteModule(backendFetcher),
    newsletterEntries: new NewsletterEntriesModule(backendFetcher)
  }

  return {
    provide: {
      backend: modules
    }
  }
})
