<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
const { loadTranslations } = useTranslations('nothing')
const { loadCurrentPortal, currentPortal } = usePortals()

await Promise.all([
  loadTranslations(),
  loadCurrentPortal()
])

useHead({
  htmlAttrs: {
    lang: currentPortal?.value?.portal?.lang ?? 'de'
  }
})
</script>

<style lang="css">
body {
  text-align: center;
}
</style>
