import { $Fetch, FetchOptions } from 'ofetch'
// import { AsyncDataOptions, UseFetchOptions } from '#app'
import { AsyncDataOptions, UseFetchOptions } from 'nuxt/app'

class HttpFactory {
  protected $fetch: $Fetch
  protected host: string|null
  protected baseUrl: string

  constructor (fetcher: $Fetch) {
    this.$fetch = fetcher
    this.host = useHost()
    this.baseUrl = useBackend().baseUrl
  }

  // Use this to make network requests based on user interaction (client side).
  // The '...FromClient' suffix should be appended in the names of all repository functions calling this.
  async callFetch<T> (
    method: string,
    url: string,
    data?: object,
    extraHeaders = {},
    extraOfetchOptions: FetchOptions<'json'> = {}
  ): Promise<T> {
    const $res = await this.$fetch<T>(
      url,
      {
        headers: {
          'travanto-host': this.host,
          ...extraHeaders
        },
        method,
        body: data,
        ...extraOfetchOptions
      }
    )

    return $res
  }

  // Use this to make network requests and use the response data both on the server and the client.
  // eslint-disable-next-line require-await
  async callUseAsyncData<T> (
    method: string,
    url: string,
    data?: object|null,
    extraHeaders = {},
    extraOfetchOptions: FetchOptions<'json'> = {},
    extraUseAsyncDataOptions: AsyncDataOptions<T> = {}
  ) {
    const $res = useAsyncData(
      url,
      async () => await this.callFetch<T>(
        method,
        url,
        data,
        extraHeaders,
        extraOfetchOptions
      ),
      {
        transform: (data) => {
          if (typeof data === 'string') {
            data = JSON.parse(data)
          }
          return data
        },
        ...extraUseAsyncDataOptions
      }
    )

    return $res
  }

  // eslint-disable-next-line require-await
  async callUseFetch<T> (
    method: string,
    url: string,
    data?: object,
    extraHeaders = {},
    extraUseFetchOptions: UseFetchOptions<T> = {}
  ) {
    const $res = useFetch(
      url,
      {
        baseURL: this.baseUrl,
        key: url,
        headers: {
          'travanto-host': this.host,
          ...extraHeaders
        },
        method,
        body: data,
        transform: (data) => {
          if (typeof data === 'string') {
            try {
              data = JSON.parse(data)
            } catch (e) {
              throw new Error(`Error in "${import.meta.url}" after calling "${this.RESOURCE}?path=${url}"\n\n${e}\n\n${data}`, {
                cause: e
              })
            }
          }
          return data
        },
        ...extraUseFetchOptions
      }
    )

    return $res
  }
}

export default HttpFactory
