import { useGtm } from '@gtm-support/vue-gtm'

class ContentModule {
  contentView (urlPathAndParams: string): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }

    const eventData = {
      event: 'content-view',
      'content-name': urlPathAndParams,
      'content-view-name': 'search'
    }

    gtm.trackEvent(eventData)
  }
}

export default ContentModule
