<template>
  <button class="underline m-5" @click="handleErrorHomepage">
    {{ t('homepage') }}
  </button>
  <button class="underline m-5" @click="handleErrorContact">
    {{ t('contact') }}
  </button>
</template>

<script setup>
const { translate: t } = useTranslations('ErrorClearButtons')
const handleErrorHomepage = () => clearError({ redirect: '/' })
const handleErrorContact = () => clearError({ redirect: '/kontakt' })
</script>
