<template>
  <NuxtLayout v-if="props.error.statusCode === 404">
    <ErrorPage404 />
    <ErrorClearButtons />
  </NuxtLayout>
  <div v-else>
    <ErrorPage :error="props.error" />
    <ErrorClearButtons />
  </div>
  <DevTestOnly>
    <div class="text-left">
      <div v-if="components.length" class="pb-5">
        <div class="font-bold">
          Component(s)
        </div>
        <div v-for="component in components" :key="component">
          {{ component }}
        </div>
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div class="font-bold">
        Stack
      </div>
      <div v-html="props.error.stack" />
      <!-- eslint-enable vue/no-v-html -->
    </div>
  </DevTestOnly>
</template>

<script setup>
const props = defineProps({
  error: {
    type: Object,
    default: null
  }
})

const { loadTranslations } = useTranslations('nothing')
await loadTranslations()

const { loadCurrentPortal } = usePortals()
await loadCurrentPortal()

// These console.log() are here to write to the CloudWatch logs
console.log('ErrorPage message: ', props.error?.message); // eslint-disable-line no-console, semi
console.log('ErrorPage stack: ', props.error?.stack); // eslint-disable-line no-console, semi

const components = computed(() => props.error?.stack?.match(/(?<=\().*?\.vue/g) ?? [])
</script>

<style lang="css">
body {
  text-align: center;
}
</style>
